import React from 'react'
import Layout from '../components/layout'
import '../css/human-capital.scss'
import Nav from '../components/nav/nav'
import ArrowRight from '../images/icons/arrow-right.svg'
import Insight from './home/insight/insight'
import HumanLetter from '../images/icons/human.svg'
import { Link } from 'gatsby'

const HumanCapitalDevelopment = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />


            <div className="human-capital pt-5 position-relative">
                <div className="position-relative">
                    {/* hero section */}
                    <div className="bg-hero py-5">
                        <div className="container my-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #ffffff' }}></div>
                                        </div>
                                        <div className="col-md-9">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '2.8rem' }}>Human Capital Development</h1>

                                            <p className="text-white mb-0" style={{ lineHeight: '28px', fontSize: '1.3rem' }}>Build your workforce and equip them with skills to build your business.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">
                                    <p className="small off-white mb-0" style={{ lineHeight: '26px', fontSize: '1.06rem' }}>Let us empower your most prized assets —your workforce — with new competencies, skills, and attitude; prepping them to be engines of growth for your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="human-letter-div mb-5">
                        <img src={HumanLetter} alt="" className="human-img" />
                    </div>
                </div>

                <div className="my-5 py-5"></div>
                <div className="my-md-5 py-md-5"></div>


                <div className="container my-5 py-5">
                    <div className="bg-training py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Training and development</h4>

                                                <p className="text-secondary"  style={{lineHeight: '26px'}}>We’ll work on and with your team, to develop training models to enhance their knowledge, skills, performance, work efficiency, competencies, and all-round self fulfilment.</p>

                                                <p className="text-secondary mb-1"  style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Build up a smarter, more efficient team.</p></li>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Improve workforce agility and tenacity.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Create tailored knowledge development materials.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Develop necessary soft skills to prep your workforce for the changing market.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Equip your workforce with technical know-how to operate in a digital environment.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Future-proof your workforce.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Motivate your employees to learn and develop new skills.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'human' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/human-capital-development-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="bg-workforce py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Workforce management technology</h4>

                                                <p className="text-secondary"  style={{lineHeight: '26px'}}>We will connect your business with the perfect technology to develop the capacity of your workforce, promote learning culture, optimize performance, propel productivity and deployment of innovative ideas.</p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Promote collaboration among your workforce.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Forecast labour requirements.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Build an efficient workforce through technology.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Automate the tasks of your HR department.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Better manage your workforce schedules, training, etc.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'human' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/human-capital-development-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-5 py-5">
                    <div className="bg-managed py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Outsourcing and managed services</h4>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will take over the task of connecting you with the right people and specialists to do the best job. We will manage the affairs of our inhouse and outsourced specialists from start to finish so that you can take a breather and focus on your core objectives.</p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Match your unique business needs with qualified professionals. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Reduce operational cost.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Increase value-added services to your customers.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Connect your business with a retinue of IT professionals.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Promote excellence in technology services.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Create a superior end-user experience </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Oversee on-site and off-site workforce operations.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'human' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/human-capital-development-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="bg-academy py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5"></div>
                                    <div className="col-md-7">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Procentric Academy</h4>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>With our years of garnered expertise in diverse fields and leveraging our team’s collective decades of experiences, it will be a joy to pass down our wealth of knowledge to your willing workforce, preparing them to apply that knowledge for the betterment of your business.</p>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We are passionate about people development and are overjoyed by the charge to deliver value and provide business-tailored development offerings. </p>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>Our curriculum is well-furnished and cuts across diverse industry-tailored knowledge and know-hows, including but not limited to ;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Information technology</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Data protection</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Cybersecurity</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Fintech</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Edtech</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Business analysis</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Project management</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Process automation</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Artificial intelligence</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>IT infrastructure</p></li>
                                                </ul>

                                                <div className="row mt-5">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-5 mb-md-0 mb-4 px-0">
                                                                <Link to={`/contact`} state={{ path: 'academy' }}><button className="btn btn-blue btn-small text-uppercase no-border-radius" style={{ fontSize: '12px' }}>Join academy</button></Link>
                                                            </div>

                                                            <div className="col-md-7 my-auto">
                                                                <Link className="my-auto" to={`/contact`} state={{ path: 'human' }}><p className="cursor black-link-text mb-0 my-auto">Request tailored curriculum <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-0 pt-4 my-auto">
                                                        <p className="my-auto font-weight-bold cursor mb-0">Download schedule</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="my-md-5 my-0 py-md-5 py-0"></div>
            <Insight />
        </Layout>
    )
}

export default HumanCapitalDevelopment
